<template>
  <div>
    <div v-if="loadingFormData" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('CATALOG_LOADING_LABEL', {catalog: $t('TURN')})  | capitalize }}</strong>
    </div>
    <b-card bg-variant="dark" text-variant="light" v-else :title="$t('FORMS_GENERAL_DATA_TITLE') | capitalize">
      <b-card-text>
        <pre v-if="debug">{{ form }}</pre>
        <b-form @submit.prevent="save">
          <!-- name text input -->
          <b-form-group
              id="name-form-group"
              :label="$t('TURN_NAME_LABEL') | capitalize"
              label-for="name-input"
              :state="state('name')">
            <b-form-input class="w-100 ml-1" id="name-input" type="text"
                          v-model="$v.form.name.$model"
                          :state="state('name')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="name-input-feedback">
              <div v-for="error in errors('name')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('TURN_NAME_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- name text input -->

          <!-- company -->
          <b-form-group
              :label="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('COMPANY')}) | capitalize"
              label-for="company-input"
              :state="state('company')">
            <company-single-selector
                id="company-input"
                undefined-means="FORMS_SELECT_ONE"
                :state="state('company')"
                :errors="errors('company')"
                v-model="$v.form.company.$model">
            </company-single-selector>
          </b-form-group>
          <!-- company -->

          <!-- nextDayIn input -->
          <b-form-group
              id="next-day-in-form-group"
              :label="$t('TURN_NEXT_DAY_IN_LABEL') | capitalize"
              label-for="next-day-in-input"
              :state="state('nextDayIn')">
            <b-checkbox v-model="$v.form.nextDayIn.$model" class="w-100 mr-1"
                        id="next-day-in-input"></b-checkbox>

            <b-form-invalid-feedback id="next-day-in-input-feedback">
              <div v-for="error in errors('nextDayIn')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('TURN_NEXT_DAY_IN_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- nextDayIn input -->

          <!-- nextDayOut input -->
          <b-form-group
              id="next-day-out-form-group"
              :label="$t('TURN_NEXT_DAY_OUT_LABEL') | capitalize"
              label-for="next-day-out-input"
              :state="state('nextDayOut')">
            <b-checkbox v-model="$v.form.nextDayOut.$model" class="w-100 mr-1"
                        id="next-day-out-input"></b-checkbox>

            <b-form-invalid-feedback id="next-day-out-input-feedback">
              <div v-for="error in errors('nextDayOut')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('TURN_NEXT_DAY_OUT_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- nextDayIn input -->

          <b-button class="mt-2" type="submit" :disabled="saving" variant="primary">
            <b-icon v-if="saving" icon="circle-fill" animation="throb" class="mr-2"></b-icon>
            <span v-if="saving">{{ $t('FORMS_SAVING_LABEL')  | capitalize }}</span>
            <span v-else>{{ $t('FORMS_SAVE_LABEL')  | capitalize }}</span>
          </b-button>
        </b-form>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {required, minLength, maxLength} from "vuelidate/lib/validators";
import {Form, ProcessWithLoadingAndMessage} from "@/mixins";
import Turn from "@/turns";
import Constants from "@/constants";
import CompanySingleSelector from "@/companies/CompanySingleSelector"

export default {
  name: "TurnForm",
  components: {CompanySingleSelector},
  mixins: [Form, ProcessWithLoadingAndMessage],
  methods: {
    async doSave(id, data) {
      return id ? await Turn.update(id, data) : await Turn.create(data);
    },
    getData() {
      this.loadingFormData = true;
      Turn.findById(this.id)
          .then(resp => {
            this.form.name = resp.data.name;
            this.form.nextDayIn = resp.data.nextDayIn;
            this.form.nextDayOut = resp.data.nextDayOut;
            if (resp.data._embedded) {
              this.form.company = resp.data._embedded.company._links.self.href.replace('{?projection}', '');
            }
            this.originals.name = resp.data.name
          })
          .catch(e => {
            if (Constants.DEBUG) {
              console.error(e);
            }
            this.sendError('%loadingFormDataErrorMessage%', e);
          })
          .finally(() => {
            this.loadingFormData = false;
          });
    }
  },
  data() {
    return {
      loadingFormData: false,
      originals: {
        name: null
      },
      form: {
        name: null,
        nextDayIn: null,
        nextDayOut: null,
        company: undefined
      },
      editPage: 'TURN_EDIT_TITLE'
    }
  },
  validations: {
    form: {
      name: {
        isUnique(value) {
          // standalone validator ideally should not assume a field is required
          if (!value || value === '') return true

          if (this.originals.name && this.originals.name === value) return true;

          // simulate async call, fail for all logins with even length
          return Turn.nameExists(value);
        }, required, minLength: minLength(4), maxLength: maxLength(100)
      }, nextDayIn: {}, nextDayOut: {}, company: {required}
    }
  }
}
</script>

<style scoped>

</style>
